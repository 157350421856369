import AboutUsInner from "../../components/aboutUsInner/AboutUsInner";

function AboutUsInnerPage() {
  return (
    <>
      <AboutUsInner />
    </>
  );
}
export default AboutUsInnerPage;
