import Faq from "../../components/faq/Faq";

function FaqPage() {
  return (
    <>
      <Faq />
    </>
  );
}
export default FaqPage;
