import React from 'react'
import { Link } from "react-router-dom";

import { LuPhoneCall } from "react-icons/lu";
import { GoMail } from "react-icons/go";
import { PiWhatsappLogoLight } from "react-icons/pi";
import { SlSocialLinkedin } from "react-icons/sl";
import { BsChatQuote } from "react-icons/bs";
import Boucher from '../contactUs/boucherDload/Boucher';
function SocialMedia() {
    return <div>
        <div className="social-media-container">
            <ul className="soc-media-list">
                <li className="media-box">
                    <Link to="tel:+91-9890938555" target='_blank'><LuPhoneCall className="social-media-link" /></Link>
                </li>
                <li className="media-box">
                    <Link to="mailto:v.laddha(@)vtechsolutions.co.in" target='_blank'><GoMail className="social-media-link" /></Link>
                </li>
                <li className="media-box">

                    <Link to="https://wa.me/9890938555" target='_blank'><PiWhatsappLogoLight className="social-media-link" /></Link>
                </li>
                <li className="media-box">
                    <SlSocialLinkedin className="social-media-link" />
                </li>
                <li className="media-box">
                    <Link to={`/contact-us`}  >
                        < BsChatQuote className="social-media-link" />
                    </Link>
                </li>
            </ul>
            <Boucher />
        </div>
    </div>
}

export default SocialMedia
