import Gallery from "../../components/gallery/Gallery";

function GalleryPage() {
  return (
    <>
      <Gallery />
    </>
  );
}
export default GalleryPage;
