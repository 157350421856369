import Desclaimer from "../../components/desclaimer/Desclaimer";

function DesclaimerPage() {
  return (
    <>
      <Desclaimer />
    </>
  );
}
export default DesclaimerPage;
