import BlogListNosidebar from "../../components/blogListNosidebar/BlogListNosidebar";

function BlogListNosidebarPage() {
  return (
    <>
      <div className="bloglist_sec_main">
        <BlogListNosidebar />
      </div>
    </>
  );
}
export default BlogListNosidebarPage;
