import BlogList from "../../components/blogList/BlogList";

function BlogListPage() {
  return (
    <div className="bloglist_sec_main">
      <BlogList />
    </div>
  );
}
export default BlogListPage;
