import Slider from "../../components/slider/Slider";

function SliderPage() {
  return (
    <>
      <Slider />
    </>
  );
}
export default SliderPage;
