import RentalProducts from "../../components/rentalProducts/RentalProducts";

function RentalProductPage() {
  return (
    <>
      <RentalProducts />
    </>
  );
}
export default RentalProductPage;
