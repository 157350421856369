import { useEffect } from "react";
import "./BlogList.css";
import BlogListContentLeft from "./blogListContent/BlogListContentLeft";
import BlogListContentRight from "./blogListContent/BlogListContentRight";

function BlogList() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  return (
    <div className="blog-section container">
      <BlogListContentLeft />
      <BlogListContentRight />
    </div>
  );
}
export default BlogList;
