import React from 'react'
import { Link } from 'react-router-dom'
import AOS from "aos"
import 'aos/dist/aos.css';

import p1 from "../../../assets/img/clientle/itCom.png"
import p2 from "../../../assets/img/clientle/pharamcy.jpg"
import p3 from "../../../assets/img/clientle/sme.png"
import p4 from "../../../assets/img/clientle/startComp.jpg"
import { useEffect } from 'react';
function OurClientele() {
    useEffect(() => {
        AOS.init({ duraition: 500, delay: 400 })
    })
    return <div className="section padding_layout_1  " style={{ marginTop: "90px" }} data-aos="fade-up">
        <div className="container "  >

            <div style={{ minWidth: "300px" }}>
                <h2 style={{ textTransform: "uppercase" }} className=" mb-5 text-center ">OUR CLIENTELE
                </h2>
            </div>
            <div className="row">

                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
                    <div className="clintle-text">
                        <div className="product_img">
                            <img
                                className="img-responsive"
                                src={p1}
                                alt="website template image"
                            />
                        </div>
                        <div className='mt-5'>
                            <h4 className='text-center'>IT & ITES COMPANIES</h4>
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
                    <div className="clintle-text">
                        <div className="product_img">
                            <img
                                className="img-responsive"
                                src={p2}
                                alt="website template image"
                            />
                        </div>
                        <div className='mt-5'>
                            <h4 className='text-center'>PHARMACY  COMPANIES</h4>
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
                    <div className="clintle-text">
                        <div className="product_img">
                            <img
                                className="img-responsive"
                                src={p3}
                                alt="website template image"
                            />
                        </div>
                        <div className='mt-5'>
                            <h4 className='text-center'>S M E COMPANIES</h4>
                            <hr />
                        </div>
                    </div>

                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
                    <div className="clintle-text">
                        <div className="product_img">
                            <img
                                className="img-responsive"
                                src={p4}
                                alt="website template image"
                            />
                        </div>
                        <div className='mt-5'>
                            <h4 className='text-center'>START-UPS COMPANIES</h4>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default OurClientele
