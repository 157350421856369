import Video from "../../components/video/Video";

function VideoPage() {
  return (
    <>
      <Video/>
    </>
  )
}
export default VideoPage;
