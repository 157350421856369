import { useEffect } from "react"
import { Helmet } from "react-helmet";
import CommonBanner from "../common/comonBanner/ComonBanner"
import '../services/service.css'
import s1 from '../../assets/img/servicesInner/s-1.jpg'
import s2 from '../../assets/img/servicesInner/s-2.jpg'
import s3 from '../../assets/img/servicesInner/s-3.jpg'
import s4 from '../../assets/img/servicesInner/s-4.jpg'
import s5 from '../../assets/img/servicesInner/s-5.jpg'
import s6 from '../../assets/img/servicesInner/s-6.jpg'
function Services() {
    const commonName = { title: 'AMC Services' }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return (
        <>
            <CommonBanner commonName={commonName.title} />
            <Helmet>
                <title>AMC services | Vtech solutions</title>
                <meta
                    name="keyword"
                    content=" services,  Services,  SERVICE"
                />
                <meta
                    name="description"
                    content="vtechsolutions is your one of the best choice."
                />
            </Helmet>
            <section className="services py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div class="full"><div class="main_heading text_align_center"><h2>AMC Services </h2></div></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={s1} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">Data recovery</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={s2} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">MACBOOK REPAIR</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={s3} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">BATTERY REPLACEMENT</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={s4} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">LAPTOP & DESKTOP REPAIR</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={s5} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">SCREEN REPLACEMENT</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={s6} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center text-center">VIRUS PROTECTION</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="product_price text-center mt-2"><a href="about-us" id="headbtn" className="tp-btn">View More  &nbsp;<i className="fas fa-angle-double-right" style={{ position: 'relative', top: 1 }} /> </a></div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Services