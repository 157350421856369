import { useEffect } from 'react'
import { Helmet } from "react-helmet";
import '../networkingService/networkingService.css'
import n1 from '../../assets/img/network/n-1.jpg'
import n2 from '../../assets/img/network/n-2.webp'
import n3 from '../../assets/img/network/n-3.jpg'
import n4 from '../../assets/img/network/n-4.jpg'
import n5 from '../../assets/img/network/n-5.png'
import CommonBanner from '../common/comonBanner/ComonBanner'
function NetworkingService() {
    const commonName = { title: 'Networking Services' }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return (
        <>
            <Helmet>
                <title>networking services | Vtech solutions</title>
                <meta
                    name="keyword"
                    content="networking services, Networking Services, NETWORKING SERVICE"
                />
                <meta
                    name="description"
                    content="vtechsolutions is your one of the best choice."
                />
            </Helmet>
            <CommonBanner commonName={commonName.title} />
            <section className="services py-5 pb-85-P">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div class="full"><div class="main_heading text_align_center"><h2>Networking Services </h2></div></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={n1} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">LAN, Wired Networking</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={n2} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center"> WAN Technologies</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={n3} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">Networking SERVICES</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={n4} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">Wireless Networks</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={n5} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">AMC for IT Infrastructure</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sevice-priview">
                                <a href="#">
                                    <div className="service-image">
                                        <img src={n1} alt="img" />
                                    </div>
                                </a>
                                <div className="service-preview-copy-wrap">
                                    <a href="#">
                                        <h4 class="service-preview-title title text-center">CLOUD SERVICES</h4>

                                    </a>
                                    {/* <a href="#" className="read-more">Read more</a> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="product_price text-center mt-2"><a href="about-us" id="headbtn" className="tp-btn">View More  &nbsp;<i className="fas fa-angle-double-right" style={{position: 'relative', top: 1}} /> </a></div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
export default NetworkingService