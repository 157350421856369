import ContactUs from "../../components/contactUs/ContactUs";

function ContactUsPage() {
  return (
    <>
      <ContactUs />
    </>
  );
}
export default ContactUsPage;
