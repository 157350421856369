import Rental from "../../components/rental/Rental";
import RentalService from "../../components/rentalService/RentalService";

function RentalPage() {
  return (
    <>
      {/* <RentalService/> */}
      <Rental />
    </>
  );
}
export default RentalPage;
