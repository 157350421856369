import React, { Component } from "react";
import Slider from "react-slick";

import "../brandBanner/BrandBann.css"

//===================== react-brand -icon ==================

import b1 from "../../assets/img/product/brand-logo/apple.png";
import b2 from "../../assets/img/product/brand-logo/dell.png";
import b3 from "../../assets/img/product/brand-logo/hp-logo.png";
import b4 from "../../assets/img/product/brand-logo/lenovo.png";
import b5 from "../../assets/img/product/brand-logo/asus.png";
import b6 from "../../assets/img/product/brand-logo/lapacare.jpg";
import b7 from "../../assets/img/product/brand-logo/microsoft.png";
import b8 from "../../assets/img/product/brand-logo/cisco.svg";
import b9 from "../../assets/img/product/brand-logo/logitech.png";

function BrandBannerHome() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 1300,
        autoplaySpeed: 400,
        slidesToShow: 7,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 1,
        autoplay: true,
        touchMove: true,
        draggable: true,
        pauseOnHover: true,
        useCSS: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false,
                    fade: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };
    return (
        <>
            <div className=" text_align_center bg-white py-3  boxDes">
                <h2 className="rounded-2 text-uppercase m-0  " id="textD"><b>Brands &nbsp;  &nbsp;WE &nbsp; &nbsp; Deals &nbsp; &nbsp; In</b>
                </h2>
            </div>
            <Slider {...settings}>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b1} alt="brand" />
                    </div>
                </div>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b2} alt="brand" />
                    </div>
                </div>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b3} alt="brand" />
                    </div>
                </div>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b4} alt="brand" />
                    </div>
                </div>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b5} alt="brand" />
                    </div>
                </div>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b6} alt="brand" />
                    </div>
                </div>

                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b7} alt="brand" />
                    </div>
                </div>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b8} alt="brand" />
                    </div>
                </div>
                <div>
                    <div className="col d-flex justify-content-center">
                        <img src={b9} alt="brand" />
                    </div>
                </div>


            </Slider>
        </>



    )
}

export default BrandBannerHome


