import TermsCondition from "../../components/termsCondition/TermCondition";

function TermsConditionPage() {
  return (
    <>
      <TermsCondition />
    </>
  );
}
export default TermsConditionPage;
