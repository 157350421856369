import ProductInner from "../../components/productInner/ProductInner";

function ProductInnerPage() {
  return (
    <>
      <ProductInner />
    </>
  );
}
export default ProductInnerPage;
