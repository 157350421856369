import Career from "../../components/career/Career";

function CareerPage() {
  return (
    <>
      <Career />
    </>
  );
}
export default CareerPage;
