import { Helmet } from "react-helmet"
import Home from "../../components/home/Home";

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Vtech solutions</title>
        <meta
          name="keyword"
          content="vtechsolutions "
        />
        <meta
          name="description"
          content="vtechsolutions is your one of the best choice."
        />
      </Helmet>
      <Home />
    </>
  );
}
export default HomePage;
