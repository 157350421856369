import Services from "../../components/services/Services";

function ServicePage() {
  return (
    <>
      <Services />
    </>
  );
}
export default ServicePage;
