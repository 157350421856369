import React from 'react'
import { useEffect } from 'react'
import { Helmet } from "react-helmet";
import tradingImg from "../../assets/img/trading/trade.jpeg"
import "./TradingServices.css"
import CommonBanner from '../common/comonBanner/ComonBanner'
import n1 from "../../assets/img/trading/extensive-range.jpg"
import n2 from "../../assets/img/trading/price-benifit.jpg"
import n3 from "../../assets/img/trading/relible-pancard.png"
import n4 from "../../assets/img/trading/genune-pro.jpg"
import n5 from "../../assets/img/trading/b2b.jpg"
import n6 from "../../assets/img/trading/custm-loyal.jpg"
import n7 from "../../assets/img/trading/expert-guidence.jpg"
import n8 from "../../assets/img/trading/cust-service.jpeg"
const tradingServices = [
    {
        id: "1",
        title: "Extensive Product Range",
        imgUrl: n1,
    },
    {
        id: "2",
        title: "Aggressive Price Benefit",
        imgUrl: n2,
    },
    {
        id: "3",
        title: "Reliable PAN INDIA Connect",
        imgUrl: n3,
    },
    {
        id: "4",
        title: "Genuine Product and Warranty",
        imgUrl: n4,
    },
    {
        id: "5",
        title: "Specialized in B2B Business",
        imgUrl: n5,
    },
    {
        id: "6",
        title: "Customer Loyalty",
        imgUrl: n6,
    },
    {
        id: "7",
        title: "Expert Guidance ",
        imgUrl: n7,
    },
    {
        id: "8",
        title: "Dedicated Customer Support",
        imgUrl: n8,
    },
]
function TradingServices() {
    const commonName = { title: 'Trading Services' }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return <>
        <Helmet>
            <title>trading services | Vtech solutions</title>
            <meta
                name="keyword"
                content="trading services, Trading Services, TRADING SERVICE"
            />
            <meta
                name="description"
                content="vtechsolutions is your one of the best choice."
            />
        </Helmet>
        <CommonBanner commonName={commonName.title} />
        <section
            className="about-area pt-30 grey-bg tp-box-space pb-20"
            style={{ background: "#fff" }}
        >
            <div className="about-wrapper">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-12 col-12 about-img">
                            <img
                                src={tradingImg}
                                style={{
                                    width: "100%"
                                }}
                                alt="about-thumb"
                            />
                        </div>
                        <div className="col-xl-6 col-lg-12 col-12">
                            <div
                                className="about-content  mb-60 wow fadeInRigh t"
                                data-wow-delay=".3s"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "0.3s",
                                    animationName: "fadeInRight",
                                }}
                            >
                                <div className="tp-section">
                                    <span className="tp-section__sub-title left-line mb-15">
                                        TRADING SERVICES
                                    </span>
                                    <h5 className="tp-sections__title mb-30">
                                        Trade Smarter Grow Faster {" "}
                                    </h5>
                                    <p className="mr-20 mb-20 text-justify">
                                        <strong>IT Equipment Sale -</strong>
                                        For businesses looking to purchase IT
                                        hardware outright, we offers a range of new equipment through
                                        our partnership with <br /> <span className='text-white bg-danger px-2 rounded-2' > Apple, Dell, HP, and Lenovo</span> at competitive
                                        prices. Our sales team works closely with you to understand your
                                        unique requirements and provide tailored recommendations.
                                    </p>
                                    <p className="mr-20 mb-30 text-justify">
                                        We
                                        assists customers in identifying the right equipment that aligns
                                        with their specific needs with competitive pricing and a focus on
                                        quality, we ensure you receive reliable equipment that aligns with
                                        your business goals.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section className="services py-5 pb-85-P">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div class="full"><div class="main_heading text_align_center"><h2><b>V &nbsp; TECH &nbsp; SOLUTIONS&nbsp; OFFERRING </b></h2></div></div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-12'>
                        <div className='row justify-content-center'>
                            {tradingServices.map((item) => {
                                return <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                                    <div className="sevice-priview boxShadow">
                                        <a href="#">
                                            <div className="service-image">
                                                <figure>
                                                    <img src={item.imgUrl} alt="img" width={100} height={100} />
                                                </figure>
                                            </div>
                                        </a>
                                        <hr />
                                        <div className="service-preview-copy-wrap text-center">
                                            <a href="#">
                                                <h4 class="service-preview-title title">{item.title}</h4>
                                            </a>
                                            {/* <a href="#" className="read-more">Read more</a> */}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>

                    {/* <div className="product_price text-center mt-2"><a href="about-us" id="headbtn" className="tp-btn">View More  &nbsp;<i className="fas fa-angle-double-right" style={{position: 'relative', top: 1}} /> </a></div> */}
                </div>
            </div>
        </section>
    </>
}

export default TradingServices
