import { useEffect } from "react";
import { Helmet } from "react-helmet"
import SliderPage from "../../pages/slider";
import SliderComp from "../slider/Slider";
import AboutUs from "./about/AboutUs";
import Banner from "./banner/Banner";
import OurClientele from "./clientele/OurClientele";
import OurProduct from "./ourProduct/OurProduct";
import Service from "./services/Service";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  return (
    <>
      <Helmet>
        <title>Home | Vtech solutions</title>
        <meta
          name="keyword"
          content="vtechsolutions "
        />
        <meta
          name="description"
          content="vtechsolutions is your one of the best choice."
        />
      </Helmet>
      <SliderPage />
      {/* <SliderComp /> */}
      <AboutUs />
      <Service />
      <OurClientele />
      <OurProduct />

    </>
  );
}
export default Home;
