import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet"
import AOS from "aos"
import 'aos/dist/aos.css';
import "../ourProduct/ourProduct.css";

import p1 from "../../../assets/img/product/apple/macbook33.jpg";
import p2 from "../../../assets/img/product/apple/quaritsch-photography-3xQ65cknLPk-unsplash.jpg";
import p3 from "../../../assets/img/product/apple/mob-work.jpg";
import p4 from "../../../assets/img/product/apple/server.jpg";
import p5 from "../../../assets/img/product/apple/it-allAcc.jpg";
import p6 from "../../../assets/img/product/apple/refusible.jpg";
import p7 from "../../../assets/img/product/apple/e-waste.jpg";
import p8 from "../../../assets/img/product/apple/macbook44.jpg";

import BrandBannerHome from "../../brandBanner/BrandBannerHome";

function OurProduct() {
  useEffect(() => {
    AOS.init({ duration: 400, delay: 600 })
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  return (
    <>
      <Helmet>
        <title> Vtech solutions</title>
        <meta
          name="keyword"
          content=" VtechSoutionsProducts,  vtechsoutionsproducts"
        />
        <meta
          name="description"
          content="vtechsolutions is your one of the best choice."
        />
      </Helmet>
      <div className="section  bg-service py-5" >
        <div className="container">

          <div style={{ minWidth: "300px" }}>
            <h2 style={{ textTransform: "uppercase" }} className=" mb-5 text-center ">OUR PRODUCTS
            </h2>
          </div>
          <div className="row" data-aos="fade-up">

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p1}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="">
                        Apple  Devices
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p2}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="" >
                        Laptops & Desktops
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p3}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="">
                        Mobile Workstation <br /> & Gaming Desktop
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p4}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="">
                        Servers & Storage
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p5}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="">
                        All IT Accessories
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p6}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="">
                        Refurbished Product
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p7}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="">
                        E-waste Management
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 margin_bottom_30_all">
              <div className="product_list">
                <div className="product_img">
                  <img
                    className="img-responsive"
                    src={p8}
                    alt="website template image"
                  />
                </div>
                <div className="product_detail_btm">
                  <div className="center">
                    <h4>
                      <Link to="">
                        AMC
                      </Link>
                    </h4>
                  </div>
                  <div className="starratin">
                    <div className="center">
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star" aria-hidden="true" />{" "}
                      <i className="fa fa-star-o" aria-hidden="true" />
                    </div>
                  </div>
                  <div className="product_price text-center mt-2">
                    {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
                    <Link to="/contact-us" id="headbtn" className="tp-btn">
                      Ask Quote &nbsp;
                      <i
                        className="fas fa-angle-double-right"
                        style={{ position: "relative", top: 1 }}
                      />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="product_price text-center mt-2">
              {/* <p><span className="old_price">$15.00</span> – <span className="new_price">$25.00</span></p> */}
              {/* <Link to="/our-product" id="headbtn" className="tp-btn">
                View More &nbsp;
                <i
                  className="fas fa-angle-double-right"
                  style={{ position: "relative", top: 1 }}
                />{" "}
              </Link> */}
            </div>
          </div>

        </div>
      </div>
      <div className="row  align-items-center brand" >
        <BrandBannerHome />
      </div>
    </>
  );
}
export default OurProduct;
