import React, { Component } from "react";
import Slider from "react-slick";
import "./InnerBann.css"
//===================== react-brand -icon ==================

import b1 from "../../../assets/img/product/brand-logo/apple.png";
import b2 from "../../../assets/img/product/brand-logo/dell.png";
import b3 from "../../../assets/img/product/brand-logo/hp-logo.png";
import b4 from "../../../assets/img/product/brand-logo/lenovo.png";
import b5 from "../../../assets/img/product/brand-logo/asus.png";
import b6 from "../../../assets/img/product/brand-logo/lapacare.jpg";
import b7 from "../../../assets/img/product/brand-logo/microsoft.png";
import b8 from "../../../assets/img/product/brand-logo/cisco.svg";
import b9 from "../../../assets/img/product/brand-logo/logitech.png";
function BannerInner() {
    const brandLogo = [
        b1, b2, b3, b4, b5, b6, b7, b8, b9
    ]
    return (
        <>
            <div className="wrapper">
                <div className="marq ">
                    <div className="marqueeGroup">
                        {brandLogo.map(item => (
                            <div className="imageGroup">
                                <img src={item} alt="" width={10} />
                            </div>
                        ))
                        }
                    </div>
                    <div className="marqueeGroup">
                        {brandLogo.map(item => (
                            <div className="imageGroup">
                                <img src={item} alt="brand" />
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default BannerInner
